<template>
    <div>
        <div style="display:flex;align-items:center;width:220px;margin:0 auto;padding:20px 0;justify-content:center ">
            <div class="circle" style="width:8px ;height:8px;margin:0 5px"></div>
            <div class="circle" style="width:10px ;height:10px;margin:0 5px"></div>
            <span style="padding:0 5px;font-weight:bold;font-size:24px">{{name}}</span>
            <div class="circle" style="width:10px ;height:10px;margin:0 5px"></div>
            <div class="circle" style="width:8px ;height:8px;margin:0 5px"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'name'
        ]
    }
</script>

<style lang="less" scoped>
    .circle {
        border-radius: 50%;
        background-image: linear-gradient(to right, #ACB2CA, #33A2C5);
    }


</style>