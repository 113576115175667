<template>
  <div class="home">
    <div class="home-container clearfix">
      <!-- 轮播图、快捷卡片 -->
      <div class="carousel">
        <el-carousel height="442px">
          <el-carousel-item v-for="item in rotationList" :key="item.id">
            <img :src="item.imgUrl" alt="" class="img" style="width: 100%; height: 442px" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 数据展示栏 -->
      <ul class="data">
        <li>
          <span>{{ statistics.amountSum | formatPrice }}</span>
          <p>累计成交金额（元）</p>
        </li>
        <li>
          <span>{{ statistics.numSum }}</span>
          <p>累计成交笔数（笔）</p>
        </li>
        <li>
          <span>{{ statistics.clientNum }}</span>
          <p>注册企业数（家）</p>
        </li>
      </ul>
      <!-- 竞买内容 -->
      <div class="auction" ref="shopping">
        <!-- 右侧导航图标 -->
        <div :class="['right-btn', isFullscroll ? 'fix-top' : '']" ref="rightBtn">
          <template>
            <div class="btn-item" :class="{hover: isHover === 'shopping'}" @click="toPageTop('shopping')"><span>竞买专场</span></div>
            <div class="line"></div>
          </template>

          <template>
            <div class="btn-item" :class="{hover: isHover === 'sell'}" @click="toPageTop('sell')"><span>竞卖专场</span></div>
            <div class="line"></div>
          </template>
          <template>
            <div class="btn-item" :class="{hover: isHover === 'bidding'}" @click="toPageTop('bidding')"><span>竞标公示</span></div>
            <div class="line"></div>
          </template>
          <template>
            <div class="btn-item" :class="{hover: isHover === 'newbox'}" @click="toPageTop('newbox')"><span>成交公告</span></div>
            <div class="line"></div>
          </template>
          <div class="btn-item" @click="backTop">
            <i class="el-icon-arrow-up"></i>
            <span>顶部</span>
          </div>
        </div>
        <div class="common-box">
          <img src="@/assets/title.png" />
          <span class="common-title">竞买专场</span>
          <span class="common-msg">提供采购方所需商品</span>
        </div>
        <div class="auction-main">
          <div>
            <div>
              <div class="auction-left">
                <img class="auction-left-buyimg" :src="hotReqProductData.reqUrl" />
                <div class="auction-left-time">
                  <div style="min-width: 100px;">
                    <div v-if="!hotBuyTimeData.isOver">
                      <span>本轮报价结束时间：</span>
                      <span class="item">{{ hotBuyTimeData.hour }}</span>
                      <span class="unit">小时</span>
                      <span class="item">{{ hotBuyTimeData.minute }}</span>
                      <span class="unit">分</span>
                      <span class="item">{{ hotBuyTimeData.second }}</span>
                      <span class="unit">秒</span>
                    </div>
                  </div>
                  <p @click="linkTo('/home/LoogFor', '', true)" style="color: #fff;cursor: pointer;">立即前往></p>
                </div>
              </div>
            </div>
          </div>
          <ul :class="['auction-right', hotReqProductData.hotList.length ? 'bidding-buy-back' : 'bidding-buy-none']">
            <li v-for="(item, index) in hotReqProductData.hotList" :key="index" @click="linkTo('/home/LoogForDetail', {id: item.productId}, true)" class="auction-right-contentBox">
              <div class="auction-right-content">
                <img :src="item.picUrl" />
                <div>
                  <p class="low-title textOverflow" :title="item.productName">{{ item.productName }}</p>
                  <div class="low-color" style="display: flex">
                    <div>规格型号：</div>
                    <div style="width: 100px" class="textOverflow" :title="item.specName">{{ item.specName }}</div>
                  </div>
                  <p class="low-color">数量：{{ item.reqNum }}{{ item.unit }}</p>

                  <div class="low-color" style="display: flex">
                    <div>买家报价：</div>
                    <div class="price">{{ item.maxPrice }}</div>
                  </div>
                </div>
              </div>
              <div>
                <span class="title" :style="getBackColor(item, 'buy').myStyle">{{ getBackColor(item, 'buy').myText }}</span>
                <span v-if="getBackColor(item, 'buy').myText !== '预告中'" class="time">距离结束：{{ countdown(item.validDate) | formatTime('time') }}</span>
                <span v-if="getBackColor(item, 'buy').myText === '预告中'" class="time">开始时间：{{ item.startTime }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 竞卖内容 -->
      <div class="auction" style="margin-top: 45px" ref="sell">
        <div class="common-box">
          <img src="@/assets/title.png" />
          <span class="common-title">竞卖专场</span>
          <span class="common-msg">报价竞拍商品</span>
        </div>
        <div class="auction-main">
          <div>
            <div>
              <div class="auction-left">
                <img class="auction-left-sellimg" :src="otherBiddingData.autionUrl" />
                <div class="auction-left-time" style="background-color: #8a67c1">
                  <span style="float: left">限时抢好货</span>
                  <span @click="linkTo('/home/Bidding', '', true)" style="float: right; color: #fff;cursor: pointer;">立即前往></span>
                </div>
              </div>
            </div>
          </div>
          <ul :class="['auction-right', otherBiddingData.autionList.length ? 'bidding-sell-back' : 'bidding-sell-none']">
            <li v-for="(item, index) in otherBiddingData.autionList" :key="index" @click="linkTo('/home/BiddingDetails', {autionInfoId: item.taId}, true)" class="auction-right-contentBox">
              <div class="auction-right-content">
                <img :src="item.picUrl" />
                <div>
                  <p class="low-title textOverflow">{{ item.prodName }}</p>
                  <div class="low-color" style="display: flex">
                    <div>规格型号：</div>
                    <div style="width: 100px" class="textOverflow" :title="item.specName">{{ item.prodModels }}</div>
                  </div>
                  <p class="low-color">数量：{{ item.amount }}</p>
                  <div class="low-color" style="display: flex">
                    <div>当前价格：</div>
                    <div class="price">{{ item.upprice }}</div>
                  </div>
                </div>
              </div>
              <div>
                <span class="title" :style="getBackColor(item, 'sell').myStyle">{{ getBackColor(item, 'sell').myText }}</span>
                <span v-if="getBackColor(item, 'sell').myText !== '预告中'" class="time">距离结束：{{ countdown(item.endTime) | formatTime('time') }}</span>
                <span v-if="getBackColor(item, 'buy').myText === '预告中'" class="time">开始时间：{{ item.startTime }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 竞标专场 -->
      <div class="bidding" ref="bidding">
        <div class="common-box">
          <img src="@/assets/title.png" />
          <span class="common-title">竞标专场</span>
          <span class="common-msg">报价竞拍批次商品</span>
        </div>
        <div class="auction-main bidding-main">
          <div class="auction-left">
            <img class="auction-left-sellimg" :src="mark.biddingUrl" />
            <div class="auction-left-time" style="background-color: #3b4c7b">
              <span style="float: left">限时抢好货</span>
              <span @click="linkTo('/home/Tender', '', true)" style="float: right; color: #fff;cursor: pointer;">立即前往></span>
            </div>
          </div>
          <div style="width: 586px" :class="!mark.tBiddingList.length ? 'bidding-background' : ''">
            <div v-if="mark.tBiddingList.length">
              <el-table
                :header-cell-style="{'padding-left': '17px', 'font-size': '16px', 'font-weight': '600', color: '#333'}"
                :cell-style="{'padding-left': '17px', 'font-size': '16px'}"
                :data="mark.tBiddingList"
                stripe
                style="width: 100%"
                @row-click="tableTo"
                :row-style="{cursor: 'pointer'}"
              >
                <el-table-column prop="bidName" label="竞标标题" width="380"> </el-table-column>
                <el-table-column label="竞标结束时间">
                  <template slot-scope="scope">
                    <div v-if="!scope.row.isOver">
                      <span class="bidding-color">{{ scope.row.hour }}</span>
                      <span>小时</span>
                      <span class="bidding-color">{{ scope.row.minute }}</span>
                      <span>分</span>
                      <span class="bidding-color">{{ scope.row.second }}</span>
                      <span>秒</span>
                    </div>
                    <div v-else>已结束</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="newbox" ref="newbox">
        <!-- 成交公告 -->
        <section class="clinch">
          <div class="clinch-title">
            <div class="clinch-title-content">
              <div class="left">
                <img src="@/assets/title.png" alt="" />
                <span>成交公告</span>
              </div>
              <span style="color: #9c9ba1; cursor: pointer" @click="linkTo('/home/ArticleContainer', {type: 'deal'}, true)">查看更多></span>
            </div>
          </div>
          <ul class="clinch-main">
            <li v-for="(item, index) in announcement" :key="index" @click="linkTo('/home/ArticleDetail', {type: 'deal', articleId: item.noticeId}, true)">
              <div class="clinch-main-box">
                <div class="clinch-main-titlt">{{ item.orderType | formatType }}</div>
                <span>{{ item.dealTime }}</span>
              </div>
              <h3 style="margin: 14px 0">{{ item.orderName }}</h3>
              <p class="overflow" :title="item.dealName">{{ item.dealName }}</p>
            </li>
          </ul>
        </section>
        <section>
          <div class="announcement">
            <div class="announcement-title">
              <div class="announcement-title-content">
                <div class="left">
                  <img src="@/assets/title.png" alt="" />
                  <span>系统公告</span>
                </div>
                <span style="color: #9c9ba1; cursor: pointer" @click="linkTo('/home/ArticleContainer', {type: 'system'}, true)">查看更多></span>
              </div>
            </div>
            <ul class="announcement-main">
              <li v-for="(item, index) in systemData" :key="index">
                <p @click="linkTo('/home/ArticleDetail', {type: 'system', articleId: item.id}, true)">{{ item.title }}</p>
                <span>{{ item.updateTime }}</span>
              </li>
            </ul>
          </div>
          <div class="announcement" style="margin-top: 14px">
            <div class="announcement-title">
              <div class="announcement-title-content">
                <div class="left">
                  <img src="@/assets/title.png" alt="" />
                  <span>新闻动态</span>
                </div>
                <span style="color: #9c9ba1; cursor: pointer" @click="linkTo('/home/ArticleContainer', {type: 'news'}, true)">查看更多></span>
              </div>
            </div>
            <ul class="announcement-main">
              <li v-for="(item, index) in newsData" :key="index">
                <p @click="linkTo('/home/ArticleDetail', {type: 'news', articleId: item.id}, true)">{{ item.title }}</p>
                <span>{{ item.updateTime }}</span>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <!-- 热门推荐 -->
      <div v-if="false" class="recommend">
        <div class="recommend-title">
          <img src="@/assets/title.png" alt="" />
          <span>热门推荐</span>
        </div>
        <ul class="recommend-main">
          <li>
            <img src="" alt="" />
            <p>dsjfuidshfudif</p>
            <div class="recommend-main-price">￥4800</div>
          </li>
          <li>
            <img src="" alt="" />
            <p>dsjfuidshfudif</p>
            <div class="recommend-main-price">￥4800</div>
          </li>
          <li>
            <img src="" alt="" />
            <p>dsjfuidshfudif</p>
            <div class="recommend-main-price">￥4800</div>
          </li>
          <li>
            <img src="" alt="" />
            <p>dsjfuidshfudif</p>
            <div class="recommend-main-price">￥4800</div>
          </li>
          <li>
            <img src="" alt="" />
            <p>dsjfuidshfudif</p>
            <div class="recommend-main-price">￥4800</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../component/Title';
import NoDataFound from '@/components/NoDataFound';
import moment from 'moment';
import {
  transactionDataStatistics,
  hotReqProduct,
  showNoticeInfo,
  showMsgPubInfo,
  recommendList,
  hotAutionInfolist,
  getCartCount,
  loginOut,
  biddingInfolistPage,
  getCompanyLogo,
  rotationImgList
} from '../api';
export default {
  data() {
    return {
      hotReqProductData: {
        hotList: []
      },
      systemData: [],
      newsData: [],
      announcement: [],
      statistics: {
        amountSum: '',
        clientNum: 0,
        numSum: 0
      },
      mark: {
        biddingUrl: '',
        tBiddingList: []
      },
      isFullscroll: false,
      isLogin: Boolean(localStorage.getItem('loginName')),
      users: localStorage.getItem('users'),
      avatar: localStorage.getItem('avatar') == 'undefined' || localStorage.getItem('avatar') == '' ? require('@/assets/photo.png') : localStorage.getItem('avatar'),
      input: '',
      Identity: 'buyer',
      recommendList: [],
      otherBiddingData: {
        autionList: [],
        autionUrl: ''
      },
      BiddingData: [
        {
          thId: undefined,
          taId: undefined,
          prodName: undefined,
          prodModels: undefined,
          upprice: undefined,
          picUrl: undefined,
          endTime: undefined,
          startTime: undefined
        }
      ],

      hotBuyTimeData: {},
      hotProductList: [],
      _timeout: null,
      bindTimeout: null,
      isHover: 'shopping',
      rotationList: []
    };
  },
  components: {
    Title,
    NoDataFound
  },
  filters: {
    formatTime(obj, type) {
      let myStr = `${obj.hour}小时${obj.minute}分钟${obj.second}秒`;
      if (obj.day) return `${obj.day}天${myStr}`;
      if (obj.isOver && type == 'time') {
        return `0小时0分钟0秒`;
      }
      if (obj.isOver && type == 'title') {
        return '已结束';
      }
      if (!obj.isOver && type == 'title') {
        return '拍卖中';
      }
      return myStr;
    },
    // 将金额格式化
    formatPrice(val) {
      val = val.toString();
      if (Number(val) <= 0 || !val) return '-';
      let myVal = [];
      let firestVal = val.substr(0, val.indexOf('.'));
      let lastVal = val.substr(-3);
      let alllength = Math.floor(firestVal.toString().length / 3);
      let headerLength = val.toString().length % 3;
      for (let i = 0; i < alllength; i++) {
        myVal.unshift(firestVal.toString().substr(i * -3 - 3, 3));
      }
      if (headerLength) myVal.unshift(val.toString().substr(0, headerLength));
      return myVal.join(',') + lastVal;
    },
    // 过滤订单类型
    formatType(val) {
      switch (val) {
        case 'AU':
          return '竞卖订单';
        case 'RE':
          return '竞买订单';
        case 'BI':
          return '竞标订单';
        case 'TS':
          return '普通订单';
      }
    }
  },
  created() {
    // 获取轮播图下的三个数据
    this.getTransactionDataStatistics();
    this.getRecommendList();
    // 热门竞买
    this.getHotReqProduct();
    // 成交公告数据
    this.getShowMsgPubInfo();
    // 获取系统公告和新闻动态
    this.getShowNoticeInfo('system');
    this.getShowNoticeInfo('news');

    // 获取竞标数据
    this.getBiddingInfolistPage();
    this.rotationImgList();
    // 获取竞卖数据
    this.getHotAutionInfolist();
    getCartCount().then(res => {
      this.$store.commit('setCarCount', res.data);
    });
    this.isLogin && this.getCompanyLogo();
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    //   获取状态
    getBackColor(data, type) {
      let myObj = {};
      let startTime = '';
      let endTime = '';
      if (type === 'buy') {
        startTime = data.startTime;
        endTime = data.validDate;
      } else {
        startTime = data.startTime;
        endTime = data.endTime;
      }
      //   预告中
      if (this.$moment(startTime).unix() > this.$moment().unix()) {
        myObj.myStyle = 'background-color: #55c176';
        myObj.myText = '预告中';
      }
      //   进行中
      if (this.$moment(startTime).unix() < this.$moment().unix() && this.$moment(endTime).unix() > this.$moment().unix()) {
        myObj.myStyle = 'background-color: #fe4247';
        myObj.myText = '进行中';
      }
      //   已结束
      if (this.$moment(endTime).unix() < this.$moment().unix()) {
        myObj.myStyle = 'background-color: #bbb';
        myObj.myText = '已结束';
      }

      return myObj;
    },
    linkTo(path, query, isOpenNewTab) {
      if (isOpenNewTab) {
        let routeData = this.$router.resolve({path, query});
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({path, query});
      }
    },
    async getBiddingInfolistPage() {
      let res = await biddingInfolistPage({page: 1, limit: 6});
      if (res.errno == 0) {
        this.mark.biddingUrl = res.data.biddingUrl;
        this.mark.tBiddingList = res.data.tBiddingList;
        if (res.data.tBiddingList.length) {
          this.bindTimeout = setInterval(() => {
            this.getBiddingData();
          }, 1000);
        }
      }
    },
    tableTo(row) {
      this.linkTo('/home/TenderDetails', {id: row.id}, true);
    },
    // 路由跳转
    // linkTo(type, path) {
    //   let routeData = this.$router.resolve({path, query: {type}});
    //   window.open(routeData.href, '_blank');
    // },
    async getHotReqProduct() {
      let res = await hotReqProduct();
      if (res.errno == 0) {
        if (res.data.hotList.length > 4) {
          this.hotReqProductData.hotList = res.data.hotList.slice(0, 4);
          this.hotReqProductData.hotList.map(item => {
            item.maxPrice = this.formatMoney(item.maxPrice);
          });
        } else {
          this.hotReqProductData.hotList = res.data.hotList;
          this.hotReqProductData.hotList.map(item => {
            item.maxPrice = this.formatMoney(item.maxPrice);
          });
        }
        this.hotReqProductData.reqUrl = res.data.reqUrl;
        this.hotReqProductData.total = res.data.total;
        this.hotReqProductData.batch_range = res.data.batch_range;
        this.hotBuyTimeData = res.data.batch_range;
      }
    },
    // 获取成交公告数据
    async getShowMsgPubInfo() {
      let res = await showMsgPubInfo({limit: 6});
      if (res.errno == 0) {
        this.announcement = res.data.data;
      }
    },
    // 获取轮播图下面的三个数据
    async getTransactionDataStatistics() {
      let res = await transactionDataStatistics();
      if (res.errno == 0) {
        this.statistics.amountSum = res.data.amountSum;
        this.statistics.clientNum = res.data.clientNum;
        this.statistics.numSum = res.data.numSum;
      }
    },
    // 获取系统公告和新闻动态
    async getShowNoticeInfo(type) {
      let res = await showNoticeInfo({noticeType: type, limit: 4});
      if (res.errno == 0) {
        type === 'system' ? (this.systemData = res.data.data) : [];
        type === 'news' ? (this.newsData = res.data.data) : [];
      }
    },
    bidingStatus(startTime, endTime) {
      startTime = startTime.replace(/-/g, '/');
      endTime = endTime.replace(/-/g, '/');
      if (new Date(startTime) > new Date()) {
        return 'preBid';
      }

      if (new Date(startTime) < new Date() && new Date(endTime) > new Date()) {
        return 'bidding';
      }

      if (new Date(endTime) < new Date()) {
        return 'bided';
      }
    },

    getRecommendList() {
      recommendList().then(res => {
        this.recommendList = res.data;
      });
    },
    getHotAutionInfolist() {
      hotAutionInfolist({
        page: 1,
        limit: 10
      }).then(res => {
        let _this = this;
        let data = res.data.autionList;
        this.otherBiddingData.autionUrl = res.data.autionUrl;
        if (res.data.autionList.length > 4) {
          this.otherBiddingData.autionList = res.data.autionList.slice(0, 4);
        } else {
          this.otherBiddingData.autionList = res.data.autionList;
        }
        data.forEach((item, index) => {
          if (_this.bidingStatus(item.startTime, item.endTime) === 'preBid') {
            // 预告中
            data[index] = Object.assign(item, {
              isOver: false,
              status: 'preBid',
              nowAmount: item.upprice
            });
          } else if (_this.bidingStatus(item.startTime, item.endTime) === 'bidding') {
            // 进行中
            data[index] = Object.assign(item, {
              isOver: false,
              status: 'bidding'
            });
          } else if (_this.bidingStatus(item.startTime, item.endTime) === 'bided') {
            // 已结束
            data[index] = Object.assign(item, {
              isOver: true,
              status: 'bided'
            });
          }

          // 计算当前价
          if (item.status === 'bidding' || item.status === 'bided') {
            const now = Date.parse(new Date());
            const overMsec = now - Date.parse(item.startTime);
            const overSecond = parseInt(overMsec / 1000 / 60);
            if (overSecond < item.continueTime) {
              data[index].nowAmount = Number(item.upprice) - overSecond * Number(item.failTactics);
            } else {
              data[index].nowAmount = Number(item.upprice) - Number(item.continueTime) * Number(item.failTactics);
            }
            if (item.nowAmount < item.bottomPrice) {
              data[index].nowAmount = item.bottomPrice;
            }
          }

          item.upprice = this.formatMoney(item.upprice);
        });
        _this.BiddingData = data;
        _this.countdown_fun();
      });
    },

    toLoogForDetail(id) {
      this.$router.push({
        path: '/home/LoogForDetail',
        query: {
          id
        }
      });
    },
    formatMoney(value) {
      //// 金额转换
      if (value !== undefined && value.toString().indexOf('.') !== -1) {
        if (value !== undefined && value.toString().split('.')[1].length == 2) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '亿元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 7) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 7) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 5) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
          /////
        } else if (value !== undefined && value.toString().split('.')[1].length == 1) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '亿元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 7) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          } else if (value !== undefined && value.toString().split('.')[0].length == 5) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 3) + '万元');
          }
          // else if (9 > value !== undefined && value.toString().split('.')[0].length > 4) {
          //   value = ((value / 10000) * 100) / 100;
          //   return (value = value.toString().slice(0, 4) + '万元');
          // }
          else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
        }

        //////////////
      } else if (value !== undefined && value.toString().length > 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '亿元');
      } else if (value !== undefined && value.toString().length == 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 4) + '亿元');
      } else if (value !== undefined && value.toString().length == 8) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 7) + '万元');
      } else if (value !== undefined && value.toString().length == 7) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 6) + '万元');
      } else if (value !== undefined && value.toString().length == 6) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length == 5) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length <= 4) {
        return (value = value + '元');
      }
    },
    // 倒计时
    countdown(endTime, showDay = false) {
      endTime = endTime.replace(/-/g, '/');
      const end = Date.parse(new Date(endTime));
      const now = Date.parse(new Date());
      const msec = end - now;
      let result = {
        isOver: false
      };
      if (msec <= 0) {
        result.isOver = true;
      } else {
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt(msec / 1000 / 60 / 60);
        let m = parseInt((msec / 1000 / 60) % 60);
        let s = parseInt((msec / 1000) % 60);
        result.hour = h > 9 ? h : '0' + h;
        result.minute = m > 9 ? m : '0' + m;
        result.second = s > 9 ? s : '0' + s;

        if (showDay && result.hour > 24) {
          //开启后大于24小时，显示天数
          result.day = d;
          result.hour = parseInt((msec / 1000 / 60 / 60) % 24);
        }
      }
      return result;
    },
    // 倒计时处理
    countdown_fun() {
      const _this = this;
      // 竞卖
      let data = _this.otherBiddingData.autionList;
      let overLen = 0;
      data.forEach((item, index) => {
        if (!item.isOver) {
          if (item.status === 'preBid') {
            // 预告中
            let result = _this.countdown(item.startTime);
            data[index] = Object.assign(item, result);
            if (result.isOver) {
              //结束处理
              data[index].status = 'bidding';
              data[index].isOver = false;
            }
          } else if (item.status === 'bidding') {
            // 进行中
            let result = _this.countdown(item.endTime);
            data[index] = Object.assign(item, result);
            if (result.isOver) {
              //结束处理
              data[index].status = 'bided';
              overLen++;
            }
          } else {
            // 已结束
            data[index].isOver = true;
            data[index].status = 'bided';
            overLen++;
          }
        } else {
          overLen++;
        }

        // 计算当前价
        if (item.status === 'bidding' || item.status === 'bided') {
          const now = Date.parse(new Date());
          const overMsec = now - Date.parse(item.startTime);
          const overSecond = parseInt(overMsec / 1000 / 60);
          if (overSecond < item.continueTime) {
            data[index].nowAmount = Number(item.upprice) - overSecond * Number(item.failTactics);
          } else {
            data[index].nowAmount = Number(item.upprice) - Number(item.continueTime) * Number(item.failTactics);
          }
          if (item.nowAmount < item.bottomPrice) {
            data[index].nowAmount = item.bottomPrice;
          }
        }
      });
      _this.otherBiddingData.autionList = JSON.parse(JSON.stringify(data));
      // 竞买
      if (_this.hotBuyTimeData.endTime) {
        let result2 = _this.countdown(_this.hotBuyTimeData.endTime);
        _this.hotBuyTimeData = Object.assign({}, _this.hotBuyTimeData, result2);
      }
      if (overLen < _this.otherBiddingData.autionList.length || !_this.hotBuyTimeData.isOver) {
        _this._timeout = setTimeout(_this.countdown_fun, 1000);
      }
    },
    // 登出
    logout() {
      var _this = this;
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        _this.$router.push('/home/Login');
      }, 500);
      _this.$message.success('退出成功');
      loginOut();
    },
    // 返回顶部
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    getBiddingData() {
      this.mark.tBiddingList.forEach(item => {
        if (item.endTime) {
          let myData = this.countdown(item.endTime);
          Object.assign(item, myData);
        }
      });
    },
    scrollToTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
      // 判断右侧图标距离顶部的距离
      let btnTop = this.$refs.shopping.offsetTop + 199;
      btnTop - scrollTop < 0 ? (this.isFullscroll = true) : (this.isFullscroll = false);
      // 根据页面滚动的距离让右侧的导航图标hover
      if (scrollTop >= this.$refs.newbox.offsetTop - 100) {
        this.isHover = 'newbox';
      } else if (scrollTop >= this.$refs.bidding.offsetTop + 190) {
        this.isHover = 'bidding';
      } else if (scrollTop >= this.$refs.sell.offsetTop + 190) {
        this.isHover = 'sell';
      } else if (scrollTop >= this.$refs.shopping.offsetTop + 190) {
        this.isHover = 'shopping';
      }
    },
    toPageTop(type) {
      this.isHover = type;
      this.$refs[type].scrollIntoView({
        behavior: 'smooth'
      });
    },
    getCompanyLogo() {
      getCompanyLogo().then(res => {
        if (res.errno === 0) {
          if (res.data.logo) {
            this.avatar = res.data.logo;
            localStorage.setItem('avatar', res.data.logo);
          }
        }
      });
    },
    // 轮播图
    rotationImgList() {
      const _this = this;
      rotationImgList({
        classify: '首页'
      }).then(res => {
        if (res.errno === 0) {
          _this.rotationList = res.data.list;
        }
      });
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    clearTimeout(this._timeout); // 当离开页面时，清除倒计时
    clearTimeout(this.bindTimeout); // 当离开页面时，清除倒计时
    window.removeEventListener('scroll', this.scrollToTop);
  }
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.fix-top {
  position: fixed !important;
  top: 20px !important;
}
.home {
  position: relative;
  background-color: #fff;
  &-container {
    padding-top: 579px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 48px;
    // 页面公共样式
    .common-box {
      height: 56px;
      line-height: 56px;
      .common-title {
        margin-left: 13px;
        font-size: 29px;
        font-weight: 600;
      }
      .common-msg {
        font-size: 19px;
        font-weight: 600;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #bbbbbb;
      }
    }
    // 轮播图
    .carousel {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    // 数据展示栏
    .data {
      width: 1200px;
      z-index: 99;
      box-shadow: 1px 3px 10px rgba(46, 110, 175, 0.2);
      background-color: #fff;
      border-radius: 5px;
      position: absolute;
      top: 408px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 110px;
      li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 85px;
        height: 70px;
        border-right: 1px solid #d9e3ed;
        &:last-child {
          border: none;
        }
        span {
          font-family: 'DOUYU';
          color: #028bf4;
          font-size: 29px;
        }
        p {
          font-weight: 600;
          text-align: center;
        }
      }
    }
    // 竞买内容和竞卖内容
    .auction {
      position: relative;
      // 右侧导航图标
      .right-btn {
        position: absolute;
        top: 56px;
        left: 50%;
        margin-left: 616px;
        width: 60px;
        border: 1px solid #e8e9ed;
        border-radius: 4px;
        padding: 5px;
        box-sizing: border-box;
        background: #ffffff;
        display: flex;
        flex-direction: column;

        .btn-item {
          width: 48px;
          height: 48px;
          padding: 8px;
          font-size: 14px;
          color: #555555;
          box-sizing: border-box;
          text-align: center;
          border-radius: 4px;
          line-height: 17px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-image: linear-gradient(to right, #00a9ff, #0a7def);
            color: #ffffff;
          }
          &.hover {
            background-image: linear-gradient(to right, #00a9ff, #0a7def);
            color: #ffffff;
          }
          span {
            width: 100%;
          }
          i {
            font-size: 20px;
          }
        }
        .line {
          border-bottom: 1px solid #e8e9ed;
          margin: 5px 0;
        }
      }
      &-main {
        display: flex;
        justify-content: space-between;
      }
      &-left {
        border: 1px solid #e3e3e3;
        &-buyimg {
          width: 590px;
          //   height: 252px;
          vertical-align: top;
        }
        &-sellimg {
          width: 590px;
          height: 312px;
          vertical-align: top;
        }
        &-time {
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          padding: 0 28px 0 22px;
          height: 47px;
          line-height: 47px;
          color: #fff;
          font-size: 19px;
          background-color: #039bfb;
          .unit {
            color: #ffd44d;
          }
        }
      }
      .bidding-buy-back {
        background: url('../image/bidding_back.png') no-repeat scroll center/100% 100%;
      }
      .bidding-buy-none {
        background: url('../image/bidding_back_none.png') no-repeat scroll center/100% 100%;
      }
      .bidding-sell-back {
        background: url('../image/sell_back.png') no-repeat scroll center/100% 100%;
      }
      .bidding-sell-none {
        background: url('../image/sell_back_none.png') no-repeat scroll center/100% 100%;
      }
      &-right {
        width: 586px;
        &-content {
          display: flex;
          margin-bottom: 22px;
        }
        .low-title {
          width: 160px;
          font-size: 15px;
          font-weight: 600;
        }
        .low-color {
          color: #888888;
        }
        &-contentBox {
          cursor: pointer;
          line-height: 20px;
          display: block;
          height: 170px;
          background-color: #fff;
          &:hover {
            transition: all 0.2s ease;
            box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.1);
          }
          .title {
            display: inline-block;
            width: 62px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 5px;
            background-color: #fe4247;
            font-size: 14px;
            color: #fff;
            margin-right: 9px;
          }
          .low-main {
            display: flex;
          }
          .time {
            font-weight: 600;
          }
          .price {
            display: inline-block;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            color: #fe4502;
            width: 100px;
          }
        }
        li {
          padding: 25px 0 0 15px;
          float: left;
          width: 283px;
          border: 1px solid #e3e3e3;
          border-radius: 5px;
          &:nth-child(odd) {
            margin-right: 20px;
          }
          &:nth-of-type(1) {
            margin-bottom: 20px;
          }
          &:nth-of-type(2) {
            margin-bottom: 20px;
          }
          &:hover {
            box-shadow: 1px 3px 10px rgb(46 110 175 / 20%);
          }
        }
        img {
          margin-right: 14px;
          width: 80px;
          height: 80px;
        }
      }
    }
    // 竞标公示
    .bidding {
      margin-top: 45px;
      &-background {
        background: url('../image/mark_back_none.png') no-repeat scroll center/100% 100%;
      }
      &-main {
        display: flex;
        justify-content: space-between;
      }
      &-color {
        color: #ff4400;
        margin: 0 5px;
        &:nth-child(1) {
          margin: 0;
        }
      }
      & /deep/ .has-gutter tr {
        background-color: #fafafa;
      }
      & /deep/ td,
      & /deep/ th {
        border-bottom: none;
      }
    }
    .newbox {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
    }
    // 成交公告
    .clinch {
      width: 779px;
      height: 460px;
      padding: 0 30px 14px 30px;
      background: url('../image/clinch.jpg') no-repeat scroll center/100% 100%;
      &-title {
        height: 74px;
        line-height: 74px;
        &-content {
          display: flex;
          justify-content: space-between;
          .left {
            span {
              font-size: 29px;
              font-weight: 600;
              margin-left: 12px;
            }
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      &-main {
        overflow: hidden;
        li {
          background-color: #fff;
          float: left;
          border: 1px solid transparent;
          border-radius: 5px;
          width: 350px;
          height: 110px;
          padding: 12px 22px;
          margin-bottom: 14px;
          cursor: pointer;
          &:nth-child(odd) {
            margin-right: 19px;
          }
        }
        &-box {
          display: flex;
          justify-content: space-between;
        }
        &-titlt {
          width: 71px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          background-color: #fe4045;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
    // 系统公告和新闻动态
    .announcement {
      width: 412px;
      height: 223px;
      padding: 0 17px 6px 17px;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      &-title {
        height: 68px;
        line-height: 68px;
        &-content {
          display: flex;
          justify-content: space-between;
          .left {
            span {
              font-size: 25px;
              font-weight: 600;
              margin-left: 12px;
            }
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      &-main {
        li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 21px;
          p {
            cursor: pointer;
            width: 223px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:hover {
              color: #008ff2;
            }
          }
        }
      }
    }
    // 热门推荐
    .recommend {
      margin-top: 59px;
      &-title {
        span {
          font-size: 29px;
          font-weight: 600;
          margin-left: 12px;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
      &-main {
        overflow: hidden;
        width: 100%;
        margin-top: 30px;
        li {
          float: left;
          width: 195px;
          margin-right: 44px;
          &:nth-last-child {
            margin: 0;
          }
          img {
            width: 100%;
            height: 193px;
          }
          p {
            margin: 14px 0;
            font-weight: 600;
          }
        }
        &-price {
          color: #fff;
          font-size: 17px;
          padding-left: 9px;
          height: 33px;
          line-height: 33px;
          background: url('../image/price.jpg') no-repeat 100% 100%;
        }
      }
    }
  }
}
</style>
