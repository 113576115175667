var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "home-container clearfix" }, [
      _c(
        "div",
        { staticClass: "carousel" },
        [
          _c(
            "el-carousel",
            { attrs: { height: "442px" } },
            _vm._l(_vm.rotationList, function (item) {
              return _c("el-carousel-item", { key: item.id }, [
                _c("img", {
                  staticClass: "img",
                  staticStyle: { width: "100%", height: "442px" },
                  attrs: { src: item.imgUrl, alt: "" },
                }),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("ul", { staticClass: "data" }, [
        _c("li", [
          _c("span", [
            _vm._v(_vm._s(_vm._f("formatPrice")(_vm.statistics.amountSum))),
          ]),
          _c("p", [_vm._v("累计成交金额（元）")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.statistics.numSum))]),
          _c("p", [_vm._v("累计成交笔数（笔）")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.statistics.clientNum))]),
          _c("p", [_vm._v("注册企业数（家）")]),
        ]),
      ]),
      _c("div", { ref: "shopping", staticClass: "auction" }, [
        _c(
          "div",
          {
            ref: "rightBtn",
            class: ["right-btn", _vm.isFullscroll ? "fix-top" : ""],
          },
          [
            [
              _c(
                "div",
                {
                  staticClass: "btn-item",
                  class: { hover: _vm.isHover === "shopping" },
                  on: {
                    click: function ($event) {
                      return _vm.toPageTop("shopping")
                    },
                  },
                },
                [_c("span", [_vm._v("竞买专场")])]
              ),
              _c("div", { staticClass: "line" }),
            ],
            [
              _c(
                "div",
                {
                  staticClass: "btn-item",
                  class: { hover: _vm.isHover === "sell" },
                  on: {
                    click: function ($event) {
                      return _vm.toPageTop("sell")
                    },
                  },
                },
                [_c("span", [_vm._v("竞卖专场")])]
              ),
              _c("div", { staticClass: "line" }),
            ],
            [
              _c(
                "div",
                {
                  staticClass: "btn-item",
                  class: { hover: _vm.isHover === "bidding" },
                  on: {
                    click: function ($event) {
                      return _vm.toPageTop("bidding")
                    },
                  },
                },
                [_c("span", [_vm._v("竞标公示")])]
              ),
              _c("div", { staticClass: "line" }),
            ],
            [
              _c(
                "div",
                {
                  staticClass: "btn-item",
                  class: { hover: _vm.isHover === "newbox" },
                  on: {
                    click: function ($event) {
                      return _vm.toPageTop("newbox")
                    },
                  },
                },
                [_c("span", [_vm._v("成交公告")])]
              ),
              _c("div", { staticClass: "line" }),
            ],
            _c("div", { staticClass: "btn-item", on: { click: _vm.backTop } }, [
              _c("i", { staticClass: "el-icon-arrow-up" }),
              _c("span", [_vm._v("顶部")]),
            ]),
          ],
          2
        ),
        _vm._m(0),
        _c("div", { staticClass: "auction-main" }, [
          _c("div", [
            _c("div", [
              _c("div", { staticClass: "auction-left" }, [
                _c("img", {
                  staticClass: "auction-left-buyimg",
                  attrs: { src: _vm.hotReqProductData.reqUrl },
                }),
                _c("div", { staticClass: "auction-left-time" }, [
                  _c("div", { staticStyle: { "min-width": "100px" } }, [
                    !_vm.hotBuyTimeData.isOver
                      ? _c("div", [
                          _c("span", [_vm._v("本轮报价结束时间：")]),
                          _c("span", { staticClass: "item" }, [
                            _vm._v(_vm._s(_vm.hotBuyTimeData.hour)),
                          ]),
                          _c("span", { staticClass: "unit" }, [_vm._v("小时")]),
                          _c("span", { staticClass: "item" }, [
                            _vm._v(_vm._s(_vm.hotBuyTimeData.minute)),
                          ]),
                          _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                          _c("span", { staticClass: "item" }, [
                            _vm._v(_vm._s(_vm.hotBuyTimeData.second)),
                          ]),
                          _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "p",
                    {
                      staticStyle: { color: "#fff", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.linkTo("/home/LoogFor", "", true)
                        },
                      },
                    },
                    [_vm._v("立即前往>")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "ul",
            {
              class: [
                "auction-right",
                _vm.hotReqProductData.hotList.length
                  ? "bidding-buy-back"
                  : "bidding-buy-none",
              ],
            },
            _vm._l(_vm.hotReqProductData.hotList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "auction-right-contentBox",
                  on: {
                    click: function ($event) {
                      return _vm.linkTo(
                        "/home/LoogForDetail",
                        { id: item.productId },
                        true
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "auction-right-content" }, [
                    _c("img", { attrs: { src: item.picUrl } }),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "low-title textOverflow",
                          attrs: { title: item.productName },
                        },
                        [_vm._v(_vm._s(item.productName))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "low-color",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("div", [_vm._v("规格型号：")]),
                          _c(
                            "div",
                            {
                              staticClass: "textOverflow",
                              staticStyle: { width: "100px" },
                              attrs: { title: item.specName },
                            },
                            [_vm._v(_vm._s(item.specName))]
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "low-color" }, [
                        _vm._v(
                          "数量：" + _vm._s(item.reqNum) + _vm._s(item.unit)
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "low-color",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("div", [_vm._v("买家报价：")]),
                          _c("div", { staticClass: "price" }, [
                            _vm._v(_vm._s(item.maxPrice)),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "title",
                        style: _vm.getBackColor(item, "buy").myStyle,
                      },
                      [_vm._v(_vm._s(_vm.getBackColor(item, "buy").myText))]
                    ),
                    _vm.getBackColor(item, "buy").myText !== "预告中"
                      ? _c("span", { staticClass: "time" }, [
                          _vm._v(
                            "距离结束：" +
                              _vm._s(
                                _vm._f("formatTime")(
                                  _vm.countdown(item.validDate),
                                  "time"
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm.getBackColor(item, "buy").myText === "预告中"
                      ? _c("span", { staticClass: "time" }, [
                          _vm._v("开始时间：" + _vm._s(item.startTime)),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "div",
        {
          ref: "sell",
          staticClass: "auction",
          staticStyle: { "margin-top": "45px" },
        },
        [
          _vm._m(1),
          _c("div", { staticClass: "auction-main" }, [
            _c("div", [
              _c("div", [
                _c("div", { staticClass: "auction-left" }, [
                  _c("img", {
                    staticClass: "auction-left-sellimg",
                    attrs: { src: _vm.otherBiddingData.autionUrl },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "auction-left-time",
                      staticStyle: { "background-color": "#8a67c1" },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v("限时抢好货"),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#fff",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.linkTo("/home/Bidding", "", true)
                            },
                          },
                        },
                        [_vm._v("立即前往>")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c(
              "ul",
              {
                class: [
                  "auction-right",
                  _vm.otherBiddingData.autionList.length
                    ? "bidding-sell-back"
                    : "bidding-sell-none",
                ],
              },
              _vm._l(_vm.otherBiddingData.autionList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "auction-right-contentBox",
                    on: {
                      click: function ($event) {
                        return _vm.linkTo(
                          "/home/BiddingDetails",
                          { autionInfoId: item.taId },
                          true
                        )
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "auction-right-content" }, [
                      _c("img", { attrs: { src: item.picUrl } }),
                      _c("div", [
                        _c("p", { staticClass: "low-title textOverflow" }, [
                          _vm._v(_vm._s(item.prodName)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "low-color",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c("div", [_vm._v("规格型号：")]),
                            _c(
                              "div",
                              {
                                staticClass: "textOverflow",
                                staticStyle: { width: "100px" },
                                attrs: { title: item.specName },
                              },
                              [_vm._v(_vm._s(item.prodModels))]
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "low-color" }, [
                          _vm._v("数量：" + _vm._s(item.amount)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "low-color",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c("div", [_vm._v("当前价格：")]),
                            _c("div", { staticClass: "price" }, [
                              _vm._v(_vm._s(item.upprice)),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "title",
                          style: _vm.getBackColor(item, "sell").myStyle,
                        },
                        [_vm._v(_vm._s(_vm.getBackColor(item, "sell").myText))]
                      ),
                      _vm.getBackColor(item, "sell").myText !== "预告中"
                        ? _c("span", { staticClass: "time" }, [
                            _vm._v(
                              "距离结束：" +
                                _vm._s(
                                  _vm._f("formatTime")(
                                    _vm.countdown(item.endTime),
                                    "time"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm.getBackColor(item, "buy").myText === "预告中"
                        ? _c("span", { staticClass: "time" }, [
                            _vm._v("开始时间：" + _vm._s(item.startTime)),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c("div", { ref: "bidding", staticClass: "bidding" }, [
        _vm._m(2),
        _c("div", { staticClass: "auction-main bidding-main" }, [
          _c("div", { staticClass: "auction-left" }, [
            _c("img", {
              staticClass: "auction-left-sellimg",
              attrs: { src: _vm.mark.biddingUrl },
            }),
            _c(
              "div",
              {
                staticClass: "auction-left-time",
                staticStyle: { "background-color": "#3b4c7b" },
              },
              [
                _c("span", { staticStyle: { float: "left" } }, [
                  _vm._v("限时抢好货"),
                ]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      float: "right",
                      color: "#fff",
                      cursor: "pointer",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.linkTo("/home/Tender", "", true)
                      },
                    },
                  },
                  [_vm._v("立即前往>")]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              class: !_vm.mark.tBiddingList.length ? "bidding-background" : "",
              staticStyle: { width: "586px" },
            },
            [
              _vm.mark.tBiddingList.length
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "header-cell-style": {
                              "padding-left": "17px",
                              "font-size": "16px",
                              "font-weight": "600",
                              color: "#333",
                            },
                            "cell-style": {
                              "padding-left": "17px",
                              "font-size": "16px",
                            },
                            data: _vm.mark.tBiddingList,
                            stripe: "",
                            "row-style": { cursor: "pointer" },
                          },
                          on: { "row-click": _vm.tableTo },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "bidName",
                              label: "竞标标题",
                              width: "380",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "竞标结束时间" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      !scope.row.isOver
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "bidding-color" },
                                              [_vm._v(_vm._s(scope.row.hour))]
                                            ),
                                            _c("span", [_vm._v("小时")]),
                                            _c(
                                              "span",
                                              { staticClass: "bidding-color" },
                                              [_vm._v(_vm._s(scope.row.minute))]
                                            ),
                                            _c("span", [_vm._v("分")]),
                                            _c(
                                              "span",
                                              { staticClass: "bidding-color" },
                                              [_vm._v(_vm._s(scope.row.second))]
                                            ),
                                            _c("span", [_vm._v("秒")]),
                                          ])
                                        : _c("div", [_vm._v("已结束")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2776436304
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _c("div", { ref: "newbox", staticClass: "newbox" }, [
        _c("section", { staticClass: "clinch" }, [
          _c("div", { staticClass: "clinch-title" }, [
            _c("div", { staticClass: "clinch-title-content" }, [
              _vm._m(3),
              _c(
                "span",
                {
                  staticStyle: { color: "#9c9ba1", cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.linkTo(
                        "/home/ArticleContainer",
                        { type: "deal" },
                        true
                      )
                    },
                  },
                },
                [_vm._v("查看更多>")]
              ),
            ]),
          ]),
          _c(
            "ul",
            { staticClass: "clinch-main" },
            _vm._l(_vm.announcement, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.linkTo(
                        "/home/ArticleDetail",
                        { type: "deal", articleId: item.noticeId },
                        true
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "clinch-main-box" }, [
                    _c("div", { staticClass: "clinch-main-titlt" }, [
                      _vm._v(_vm._s(_vm._f("formatType")(item.orderType))),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.dealTime))]),
                  ]),
                  _c("h3", { staticStyle: { margin: "14px 0" } }, [
                    _vm._v(_vm._s(item.orderName)),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "overflow",
                      attrs: { title: item.dealName },
                    },
                    [_vm._v(_vm._s(item.dealName))]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
        _c("section", [
          _c("div", { staticClass: "announcement" }, [
            _c("div", { staticClass: "announcement-title" }, [
              _c("div", { staticClass: "announcement-title-content" }, [
                _vm._m(4),
                _c(
                  "span",
                  {
                    staticStyle: { color: "#9c9ba1", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.linkTo(
                          "/home/ArticleContainer",
                          { type: "system" },
                          true
                        )
                      },
                    },
                  },
                  [_vm._v("查看更多>")]
                ),
              ]),
            ]),
            _c(
              "ul",
              { staticClass: "announcement-main" },
              _vm._l(_vm.systemData, function (item, index) {
                return _c("li", { key: index }, [
                  _c(
                    "p",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.linkTo(
                            "/home/ArticleDetail",
                            { type: "system", articleId: item.id },
                            true
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _c("span", [_vm._v(_vm._s(item.updateTime))]),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "announcement",
              staticStyle: { "margin-top": "14px" },
            },
            [
              _c("div", { staticClass: "announcement-title" }, [
                _c("div", { staticClass: "announcement-title-content" }, [
                  _vm._m(5),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#9c9ba1", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.linkTo(
                            "/home/ArticleContainer",
                            { type: "news" },
                            true
                          )
                        },
                      },
                    },
                    [_vm._v("查看更多>")]
                  ),
                ]),
              ]),
              _c(
                "ul",
                { staticClass: "announcement-main" },
                _vm._l(_vm.newsData, function (item, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "p",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.linkTo(
                              "/home/ArticleDetail",
                              { type: "news", articleId: item.id },
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _c("span", [_vm._v(_vm._s(item.updateTime))]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
      false
        ? _c("div", { staticClass: "recommend" }, [_vm._m(6), _vm._m(7)])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "common-box" }, [
      _c("img", { attrs: { src: require("@/assets/title.png") } }),
      _c("span", { staticClass: "common-title" }, [_vm._v("竞买专场")]),
      _c("span", { staticClass: "common-msg" }, [_vm._v("提供采购方所需商品")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "common-box" }, [
      _c("img", { attrs: { src: require("@/assets/title.png") } }),
      _c("span", { staticClass: "common-title" }, [_vm._v("竞卖专场")]),
      _c("span", { staticClass: "common-msg" }, [_vm._v("报价竞拍商品")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "common-box" }, [
      _c("img", { attrs: { src: require("@/assets/title.png") } }),
      _c("span", { staticClass: "common-title" }, [_vm._v("竞标专场")]),
      _c("span", { staticClass: "common-msg" }, [_vm._v("报价竞拍批次商品")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("img", { attrs: { src: require("@/assets/title.png"), alt: "" } }),
      _c("span", [_vm._v("成交公告")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("img", { attrs: { src: require("@/assets/title.png"), alt: "" } }),
      _c("span", [_vm._v("系统公告")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("img", { attrs: { src: require("@/assets/title.png"), alt: "" } }),
      _c("span", [_vm._v("新闻动态")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "recommend-title" }, [
      _c("img", { attrs: { src: require("@/assets/title.png"), alt: "" } }),
      _c("span", [_vm._v("热门推荐")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "recommend-main" }, [
      _c("li", [
        _c("img", { attrs: { src: "", alt: "" } }),
        _c("p", [_vm._v("dsjfuidshfudif")]),
        _c("div", { staticClass: "recommend-main-price" }, [_vm._v("￥4800")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "", alt: "" } }),
        _c("p", [_vm._v("dsjfuidshfudif")]),
        _c("div", { staticClass: "recommend-main-price" }, [_vm._v("￥4800")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "", alt: "" } }),
        _c("p", [_vm._v("dsjfuidshfudif")]),
        _c("div", { staticClass: "recommend-main-price" }, [_vm._v("￥4800")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "", alt: "" } }),
        _c("p", [_vm._v("dsjfuidshfudif")]),
        _c("div", { staticClass: "recommend-main-price" }, [_vm._v("￥4800")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "", alt: "" } }),
        _c("p", [_vm._v("dsjfuidshfudif")]),
        _c("div", { staticClass: "recommend-main-price" }, [_vm._v("￥4800")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }