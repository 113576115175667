var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          width: "220px",
          margin: "0 auto",
          padding: "20px 0",
          "justify-content": "center",
        },
      },
      [
        _c("div", {
          staticClass: "circle",
          staticStyle: { width: "8px", height: "8px", margin: "0 5px" },
        }),
        _c("div", {
          staticClass: "circle",
          staticStyle: { width: "10px", height: "10px", margin: "0 5px" },
        }),
        _c(
          "span",
          {
            staticStyle: {
              padding: "0 5px",
              "font-weight": "bold",
              "font-size": "24px",
            },
          },
          [_vm._v(_vm._s(_vm.name))]
        ),
        _c("div", {
          staticClass: "circle",
          staticStyle: { width: "10px", height: "10px", margin: "0 5px" },
        }),
        _c("div", {
          staticClass: "circle",
          staticStyle: { width: "8px", height: "8px", margin: "0 5px" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }